export const control = {
    panSpeed: 15, // vitesse de la caméra
    dragLerp: 3, // smooth du drag de camera
    swapLerp: 2, // smooth du click sur les points d'interets
    localPanRange: 1, // amplitudes du mouvement de caméra au mousemove

    defaultRotY: -0.6,
    defaultRotX: -0.9,
    // defaultPosY: 100,

    cameraMinX: -15,
    cameraMaxX: 15,
    cameraMinXMobile: -30,
    cameraMaxXMobile: 30,
    cameraMinZ: 0,
    cameraMaxZ: 20,
    cameraMinZMobile: -20,
    cameraMaxZMobile: 20,
};

// Interest points
export const interest = {
    spriteScale: 0.035,
    spriteYElevation: 1,
};
